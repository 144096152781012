import {defineStore} from "pinia";
import { MapDataService } from "../../common/api.service";
import L from "leaflet";
import { GeoJsonObject } from 'geojson';
import {MapFeature} from "../../type";

export interface MapState {
    state: String,
    geoJson?: GeoJsonObject;
    bounds?: L.LatLngBounds;
    zoom: number;
    selectedFeature: MapFeature|null;

    error: any,
    loading: boolean,
}

export const useMapStore = defineStore('map', {
    state: () : MapState => ({
        state: 'none',
        geoJson: undefined,
        bounds: undefined,
        zoom: 17,
        selectedFeature: null,

        error: null,
        loading: false,
    }),
    getters: {},
    actions: {
        fetchData() {
            const bounds : L.LatLngBounds = this.bounds;
            if (bounds === undefined) {
                throw new Error('Bounds are not defined');
            }

            this.loading = true;

            MapDataService.getGeoJson(bounds)
                .then((response) => {
                    this.geoJson = response && response.data;

                    if (!this.geoJson.features) {
                        this.geoJson.features = [];
                    }
                })
                .catch((error) => {
                    console.log('Error fetching map data', error);
                    this.error = error;
                }).finally(() => {
                    this.loading = false;
                });
        },
        updateBound(bounds: L.LatLngBounds) : any {
            this.bounds = bounds;
        },
        updateZoom(zoom: Number) : any {
            this.zoom = zoom;
        }
    },
})