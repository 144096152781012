import { defineStore } from "pinia";
import {PlayerItemService} from "../../common/api.service";
import {ApiError, Item, PlayerItem} from "../../type";

interface State {
    open: boolean;

    items: { [key: number]: PlayerItem };
    itemsLoading: boolean;
    itemsError: ApiError|null;

    current: Item | null;
    currentLoading: boolean;
    currentError: ApiError|null;
}

export const usePlayerInventoryStore = defineStore('playerInventory', {
    state: () : State => ({
        open: false,

        items: {},
        itemsLoading: false,
        itemsError: null,

        current: null,
        currentLoading: false,
        currentError: null,
    }),
    getters: {},
    actions: {
        async get(id: string) {
            this.currentLoading = true;
            this.currentError = null;

            PlayerItemService.getItem(id)
                .then((response) => {
                    this.current = response.data;
                })
                .catch((error) => {
                    this.currentError = error;
                });

            this.currentLoading = false;
        },

        async fetchPlayerItems() {
            this.itemsLoading = true;
            this.itemsError = null;

            PlayerItemService.getItems()
                .then((response) => {
                    this.items = response.data;
                })
                .catch((error) => {
                    this.itemsError = error;
                });

            this.itemsLoading = false;
        },

        async pickUp(id: string) {
            PlayerItemService.pickUp(id)
                .then(() => {
                    this.fetchPlayerItems();
                });
        },

        async drop(id: string) {
            PlayerItemService.drop(id)
                .then(() => {
                    this.fetchPlayerItems();
                });
        },
    },
})