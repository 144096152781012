import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../view/HomeView.vue'
import DashboardView from "../view/DashboardView.vue";
import MapView from "../view/MapView.vue";
import HeroView from "../view/HeroView.vue";
import SignView from "../view/SignView.vue";

import {Collapse} from "bootstrap";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: '',
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView,
        meta: {
            title: 'Přehled',
        }
    },
    {
        path: '/map',
        name: 'map',
        component: MapView,
        meta: {
            title: 'Mapa',
        }
    },
    {
        path: '/hero',
        name: 'hero',
        component: HeroView,
        meta: {
            title: 'Hrdina',
        }
    },
    {
        path: '/sign',
        name: 'sign',
        component: SignView,
        meta: {
            title: 'Přihlášení',
        }
    },
];

const router = createRouter({
    history: createWebHistory('/game'),
    routes,
    linkActiveClass: 'active',
    scrollBehavior() {
        return new Promise((resolve) => {
            resolve({ left: 0, top: 0 })
        })
    },
});

router.beforeEach((to) => {

    // Hide the main menu
    const mainMenu = Collapse.getInstance(document.getElementById('mainMenu'));
    if (mainMenu) {
        mainMenu.hide();
    }

    // Update the title
    const parsedTitle = document.title.split(' | ');
    const newTitleParts = to.meta.title !== '' ? [to.meta.title] : [];
    newTitleParts.push(parsedTitle[parsedTitle.length - 1]);
    document.title = newTitleParts.join(' | ');
})
export default router